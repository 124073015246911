export default {
  username: "",
  title: "闲牛租后台管理系统",
  //品牌
  save_brand_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savebrand",
  get_brand_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getbrand",
  enable_brand_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablebrand",
  disable_brand_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablebrand",
  del_brand_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delbrand",
  //品类
  save_category_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savecategory",
  get_category_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcategory",
  enable_category_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablecategory",
  disable_category_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablecategory",
  del_category_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delcategory",
  get_tree_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=gettree",
  //型号
  save_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savemodel",
  get_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getmodel",
  enable_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablemodel",
  disable_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablemodel",
  del_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delmodel",
  get_select_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getselmodel",
  //参数
  save_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveparam",
  get_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getparam",
  enable_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enableparam",
  disable_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disableparam",
  del_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delparam",
  get_param_val_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getparamval",
  save_param_val_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveparamval",
  del_param_val_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delparamval",
  //产品
  upload_pdt_detail_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadtempimg",
  upload_images:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadimages",
  //upload_pdt_detail_url:"http://www.xnzj.com/data.php?op=uploadtempimg",
  save_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savepdt",
  get_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpdt",
  //get_pdt_url:"http://www.xnzj.com/data.php?op=getpdt",
  enable_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablepdt",
  disable_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablepdt",
  get_sel_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=editpdt",
  del_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delpdt",
  get_pdt_detail_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpdtdetail",
  get_pdt_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpdtplan",
  savesetprice:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savesetprice",
  getpdttemp:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpdttemp",
  copypdt: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=copypdt",
  getpdtparams:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpdtparams",
  lockpdt: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=lockpdt",
  unlock: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=unlock",
  industry:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=industrylist",
  //模板
  save_temp_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savetemp",
  get_temp_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=gettemp",
  enable_temp_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enabletemp",
  disable_temp_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disabletemp",
  del_temp_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=deltemp",
  upload_temp_img_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadtempimg",
  upload_temp_img_url2:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadtempimglocal",
  get_temp_detail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getdetail",
  //级联
  get_cascader_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=cascader",
  //套餐
  get_plan_model_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=planmodel",
  get_plan_pdt_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=planpdt",
  get_plan_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=planparam",
  get_sel_param_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=selparam",
  save_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveplan",
  get_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getplan",
  del_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delplan",
  enable_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enableplan",
  disable_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disableplan",
  edit_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=editplan",
  get_plan_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=planguarant",
  save_edit_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveedit",
  batchsave_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=batchsaveplan",
  batch_add_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=batchaddplan",
  check_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=checkplan",
  temp_save_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=tempsaveplan",
  get_example_plan_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=exampleplan",
  get_plan_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getplanlist",
  //保障服务
  save_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveguarant",
  get_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getguarant",
  enable_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enableguarant",
  disable_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disableguarant",
  del_guarant_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delguarant",
  //公告
  save_notice_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savenotice",
  get_notice_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getnotice",
  enable_notice_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablenotice",
  disable_notice_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablenotice",
  del_notice_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delnotice",
  get_notice_detail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getnoticedetail",
  //公用
  updatesession:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=updatesession",
  get_rank_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getrank",
  get_cate_tree_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcatetree",
  batch_edit_plan:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=batchplan",
  get_region:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getregion",
  get_logistic_company:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getlogcom",
  get_return_addr:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getreturnaddr",
  fileupload:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=fileupload",
  uploadid: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadid",
  savelicense:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savelicense",
  statisticinfo:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=homepage",
  //库存
  get_stock_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getstock",
  save_stock_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savestock",
  //账单
  get_bill_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getbill",
  save_offline_bill:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveoffline",
  withdraw_bill_url:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=withdrawbill",
  refundlist:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=refundlist",
  updaterefund:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=updaterefund",
  getoplog: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getoplog",
  //订单
  getproof: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getproof",
  uploadproof:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=uploadproof",
  get_order_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getorderlist",
  get_order_list2:
    "https://xuanzj.xianniuzu.com/static/includes/xinjiang.php?op=getorderlist2",
  get_order:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getorderprice",
  get_apply_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getapply",
  save_buyout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savebuyout",
  save_cancel_order:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savecancel",
  turn_overdue:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=turnoverdue",
  set_receive:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=setreceive",
  set_complete:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=setcomplete",
  set_consign:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=setconsign",
  save_logistic:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savelogistic",
  get_order_logistic:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getlogistic",
  get_customer_addr:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcustomeraddr",
  save_addr:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savecustomeraddr",
  confirm_order:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=confirmorder",
  save_addition:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveaddition",
  get_info:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=orderdetail",
  save_offline:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveoffline",
  get_logistic_detail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=logisticdetail",
  get_orderdetail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=orderdetail",
  get_order_bill:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=orderbill",
  get_billno: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=billno",
  save_pbi: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savepbi",
  save_offincome:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveincome",
  get_trade:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=gettrade",
  exportbilldata: "https://api.xianniuzu.com:444/export",
  get_decrease:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getordermoney",
  get_deposit:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getdeposit",
  set_buyoutprice:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=setbuyout",
  confirm_ReReceive:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=rereceive",
  get_estimate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getestimate",
  get_estimate_order:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getestimateorder",
  save_estimate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveestimate",
  add_remark:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=addremark",
  add_evidence:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=addevidence",
  get_seller_remarks:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=viewnotes",
  getev: "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getev",
  export: "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=export",
  getevall:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=exportall",
  uploadfile:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=uploadfile",
  get_seller_remark:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getremark",
  getcompesation:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcompesation",
  save_mitigate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savemitigate",
  getadditioninfo:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getadditioninfo",
  restoreorder:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=restoreorder",
  checkreturn:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=checkreturn",
  riskinfo: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=riskinfo",
  savereject:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savereject",
  alterorder:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=alterorder",
  savealter:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savealter",
  extendorder:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=extendorder",
  saveextend:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveextend",
  //系统，角色管理
  get_role_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getrolelist",
  get_role: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getrole",
  set_role:
    "https://xuanzj.xianniuzu.com/static/includes/index.php?entry=role&op=saverole",
  operator_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getoplist",
  save_operator:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveop",
  get_permission:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getpermission",
  save_role:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saverole",
  get_operator:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getopinfo",
  save_password:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savepassword",
  delrole: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delrole",
  update_status:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=changestatus",
  delop: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delop",
  //登录
  login_url: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=login",
  //优惠卷
  save_coupon:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savecoupon",
  // save_coupon:'http://www.xnz.com/data.php?op=savecoupon',
  get_coupon_list:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcouponlist",
  // get_coupon_list:"http://www.xnz.com/data.php?op=getcouponlist",
  disable_coupon:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablecoupon",
  del_coupon:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delcoupon",
  get_coupon_detail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcoupondetail",
  // get_coupon_detail:"http://www.xnz.com/data.php?op=getcoupondetail",
  get_sel_plan:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getselplanlist",
  // get_sel_plan:"http://www.xnz.com/data.php?op=getselplanlist",
  get_pdt_param:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=get_pdt_param",
  //商户
  savemerchant:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savemerchant",
  //savemerchant:"http://www.xnz.com/data.php?op=savemerchant",
  merchantlist:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=merchantlist",
  //merchantlist:'http://www.xnz.com/data.php?op=merchantlist',
  disablemerchant:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disablemerchant",
  enablemerchant:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enablemerchant",
  disableshop:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=disableshop",
  enableshop:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=enableshop",
  getmerchant:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getmerchant",
  // allmerchant:
  // "https://xuanzj.xianniuzu.com/static/includes/data.php?op=allmerchant",
  allmerchant: "https://api.xianniuzu.com:444/getList",
  agreementpay:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=agreementpay",
  lianlianpay:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=lianlianpay",
  lianlianfullrefund:
    // "https://xuanzj.xianniuzu.com/static/includes/request.php?op=lianlianrefund",
    "https://api.xianniuzu.com:444/lianLianRefund",
  lianliansectionrefund:
    "https://xuanzj.xianniuzu.com/static/includes/request.php?op=lianliansectionrefund",
  //风控
  saveriskmsg:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveriskmsg",
  editriskmsg:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=editriskmsg",
  pushmsg: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=pushmsg",
  //提现
  savecashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savecashout",
  addcashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=addcashout",
  cashoutlist:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=cashoutlist",
  export3:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=export3",
  getcashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getcashout",
  updatecashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=updatecashout",
  cashoutlog:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=cashoutlog",
  completecashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=completecashout",
  checkcashout:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=checkcashout",
  //结算
  savesettle:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=savesettle",
  getsettle:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getsettle",
  export2:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=export2",
  sncinfo: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=sncinfo",
  alterorderrate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=alterorderrate",
  //调拨
  targetpdt:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=targetpdt",
  getassociate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getassociate",
  saveass: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveass",
  getasslist:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getasslist",
  changeassstatus:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=changeassstatus",
  associatepdt:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=associatepdt",
  delass: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=delass",
  getassdetail:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getassdetail",
  allocatelist:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=allocatelist",
  //allocatelist:"http://www.xnz.com/data.php?op=allocatelist",
  getassmerchant:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getassmerchant",
  saveallocate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=saveallocate",
  rejectallocate:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=rejectallocate",
  //rejectallocate:"http://www.xnz.com/data.php?op=rejectallocate",
  allocatelog:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=allocatelog",
  getallorder:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getallorder",
  asslog: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=asslog",
  reject: "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=reject",
  //生成商品二维码
  //get_qrcode:"http://www.xnzj.com/datatwo.php?op=getQRcode",
  get_qrcode:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getQRcode",
  //获取身份证是否存在
  //get_identity_id :"http://www.xnzj.com/datatwo.php?op=getIdentityId",
  get_identity_id:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getIdentityId",
  //获取交易快照
  // get_trades:"http://www.xnz.com/datatwo.php?op=gettrades",
  get_trades:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=gettrades",
  //增加只有管理员才能删除商品权限
  get_operator_control:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getcontrol",
  //上传保险
  insurance:
    "https://xuanzj.xianniuzu.com/static/includes/insurance.php?option=main",
  //商户注册
  merchant_user:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=merchant_user",
  //merchant_user:"http://www.xnz.com/data.php?op=merchant_user",
  //店铺数据
  statistics:
    "https://xuanzj.xianniuzu.com/static/includes/statistics.php?option=statistics",
  //查询店铺数据
  searchstatistics:
    "https://xuanzj.xianniuzu.com/static/includes/statistics.php?option=search",
  //验签报告
  // cunzheng:"http://www.xnz.com/datatwo.php?op=cunzheng",
  cunzheng:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=cunzheng",
  //入驻商家
  merchantruzhu:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=merchantruzhu",
  //还款详情
  huankuandetail:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=huankuandetail",
  //扣款
  handmoney:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=handmoney",
  //扣款返回的数据
  cutmoney:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=cutmoney",
  //审核店铺
  shenhe: "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=shenhe",
  //添加商品详情活动
  addgoodsdetail:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=addgoodsdetail",
  // addgoodsdetail:"http://www.xnz.com/datatwo.php?op=addgoodsdetail",
  //商品详情活动列表
  // goodsdetail:"http://www.xnz.com/datatwo.php?op=goodsdetail",
  goodsdetail:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=goodsdetail",
  //查看商户
  lookmerchant:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=lookmerchant",
  //编辑活动
  getedit:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getedit",
  //删除活动
  deldetail:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=deldetail",
  //保障服务
  getguarantdetail:
    "https://xuanzj.xianniuzu.com/static/includes/guarant.php?op=getguarant",
  getguarantlist:
    "https://xuanzj.xianniuzu.com/static/includes/guarant.php?op=getguarantlist",
  saveitem:
    "https://xuanzj.xianniuzu.com/static/includes/guarant.php?op=saveitem",
  saveodd:
    "https://xuanzj.xianniuzu.com/static/includes/guarant.php?op=saveodd",
  odddel: "https://xuanzj.xianniuzu.com/static/includes/guarant.php?op=odddel",
  getagent: "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getagent",
  getallpartner:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=getallpartner",
  //手动获取风控
  handrisk: "https://xuanzj.xianniuzu.com/alimini/handrisk.php",
  //更新身份证
  updatecard:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=updatecard",
  //导出数据
  exportdata2:
    "https://xuanzj.xianniuzu.com/static/includes/xinjiang.php?op=exportdata2",
  exportdata:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=exportdata",
  exportdataOperation:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=exportdataOperation",
  exportdataFinance:
    "https://xuanzj.xianniuzu.com/static/includes/data.php?op=exportdataFinance",
  //移动账单
  movebill:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=movebill",
  //移动账单2
  movebill2:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=movebill2",
  //退全款
  fullrefund:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=fullrefund",
  //部分退款详情
  getrefundbill:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getrefundbill",
  //可退款查询
  searchrefund:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=searchrefund",
  //获取授权单号
  getauth:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getauth",
  //修改状态
  editstatus:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=editstatus",
  //溢价率
  premiumrate:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=premiumrate",
  //续租
  relet: "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=relet",
  //驳回
  bohui: "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=bohui",
  //个人资料
  personinfo:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=personinfo",
  //保存个人资料
  savepersoninfo:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=savepersoninfo",
  //商户资料
  merchantinfo:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=merchantinfo",
  //获取发票
  invoice:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=invoice",
  //发票申请
  invoiceapply:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=invoiceapply",
  //一键申请
  getinvoice:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getinvoice",
  //申请开发票
  invoicelist:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=invoicelist",
  //完成开票
  completeinvoice:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=completeinvoice",
  //查看开票详情
  getidinvo:
    "https://xuanzj.xianniuzu.com/static/includes/datatwo.php?op=getidinvo",
  //提现
  alipay:
    "https://xuanzj.xianniuzu.com/static/includes/alipayrequest.php?op=alipay",
  //补押金
  newdeposit:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=newdeposit2",
  fill_first_pay:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=fill_first_pay",
  //查看审核资料
  getaudit:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getaudit",
  getaudit2:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getaudit2",
  saveagent:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=saveagent",
  getpartner:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getpartner",
  savedb: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=savedb",
  syncagent:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=syncagent",
  getjiaofu:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getjiaofu",
  saveonekey:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=saveonekey",
  jiaofuconfirm:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=jiaofuconfirm",
  getrecharge:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=getrecharge",
  saverecharge:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=saverecharge",
  recharge:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=recharge",
  goalipay:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=alipay",
  savenumber:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=savenumber",
  getrechargedetail:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=getrechargedetail",
  changstatus:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=changstatus",
  getaccount:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=getaccount",
  changeamount:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=changeamount",
  checkdb:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=checkdb",
  detelepartner:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=dpartner",
  editpartner:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=editpartner",
  savepartner2:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=savepartner2",
  createaccount:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=createaccount",
  getpart:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getparts",
  savedp: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=savedp",
  getdata:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getdata",
  getMonth:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getMonth",
  tuikuan:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=tuikuan",
  remarksave:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=remarksave",
  getstore:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getstore",
  getstores:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getstores",
  get_member:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=get_member",
  laheiuser:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=laheiuser",
  get_ywy:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=get_ywy",
  addywy: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=addywy",
  getywysettle:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getywysettle",
  savepercent:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=savepercent",
  savepenalty:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=savepenalty",
  getywy: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getywy",
  getywydata:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=getywydata",
  saveywy:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=saveywy",
  salary: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=salary",
  getdeduct:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getdeduct",
  export5:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=export5",
  undeduct:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=undeduct",
  deduct: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=deduct",
  export4:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=export4",
  backout:
    "https://xuanzj.xianniuzu.com/static/includes/recharge.php?op=backout",
  editprice:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=editprice",
  //微信退全款//微信部分退款详情type 1 退全款 2 退部分
  wxfullrefund:
    "https://tiyan.xianniuzu.com/admin/wxfullrefund.php?op=wxfullrefund",
  wxrefund: "https://api.xianniuzu.com:444/wxRefund",
  //退补押金
  wxyajinrefund:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=wxyajinrefund",
  jindun:
    "https://xuanzj.xianniuzu.com/static/includes/wxrequest.php?op=jindun",
  esign: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=esign",
  CreateConsoleLoginUrl:
    "https://xuanzj.xianniuzu.com/tencent/esign.php?op=CreateConsoleLoginUrl",
  deleteflow:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=deleteflow",
  pinned: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=pinned",
  limit: "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=limit",
  ywy: "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=ywy",
  DescribeResourceUrlsByFlows:
    "https://xuanzj.xianniuzu.com/tencent/esign.php?op=DescribeResourceUrlsByFlows",
  savedy:
    "https://xuanzj.xianniuzu.com/alimini/index.php?entry=realname&op=savememberinfo",
  dyfullrefund:
    "https://xuanzj.xianniuzu.com/static/includes/douyin.php?op=dyfullrefund",
  lianlian:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=lianlian",
  lianlianQiYe:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=lianlianQiYe",
  chaxunyue:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=chaxunyue",
  tixian: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=tixian",
  LianLianMianYan:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=LianLianMianYan",
  lianlianjl:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=lianlianjl",
  lianlianwxfullrefund:
    "https://xuanzj.xianniuzu.com/static/includes/request.php?op=lianlianwxfullrefund",
  saveReceiver:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=saveReceiver",
  onepay: "https://xuanzj.xianniuzu.com/check/onepay.php",
  updateInfo:
    "https://xuanzj.xianniuzu.com/static/includes/request.php?op=updateInfo",
  changbank:
    "https://xuanzj.xianniuzu.com/static/includes/request.php?op=changbank",
  delruzhang:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=delruzhang",
  ruzhang:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=ruzhang",
  editfh: "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=editfh",
  geteveryday:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=everyday",
  overduedatas:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=overduedatas",
  checkWork:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=checkWork",
  checkWork2:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=checkWork2",
  getCity: "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=getCity",
  saveCity: "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=saveCity",
  deteleCity:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=deteleCity",
  changeStatus:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=changeStatus",
  editCity: "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=editCity",
  getCityData:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=getCityData",
  dayMonthData:
    "https://xuanzj.xianniuzu.com/static/includes/ywy.php?op=dayMonthData",
  getMonthData:
    "https://xuanzj.xianniuzu.com/static/includes/merchant.php?op=getMonthData",
  allstore: "https://api.xianniuzu.com:444/allstore",
  allarea: "https://api.xianniuzu.com:444/allarea",
  getFundProfile: "https://api.xianniuzu.com:444/getFundProfile",
  orderList: "https://api.xianniuzu.com:444/orderList",
  getplacenum: "https://api.xianniuzu.com:444/getPlaceNum",
  points: "https://api.xianniuzu.com:444/points",
  modifyfenzhang: "https://api.xianniuzu.com:444/modifyFZ",
  miniOrderModify: "https://api.xianniuzu.com:444/miniOrderModify",
  miniOrderDeliveryModify:
    "https://api.xianniuzu.com:444/miniOrderDeliveryModify",
  riskQuery: "https://api.xianniuzu.com:444/riskQuery",
  orderDeliverySend: "https://api.xianniuzu.com:444/orderDeliverySend",
  orderDeliveryReceive: "https://api.xianniuzu.com:444/orderDeliveryReceive",
  auditIs: "https://api.xianniuzu.com:444/auditIs",
  exportRecharge: "https://api.xianniuzu.com:444/exportRecharge",
  exportExcel: "https://api.xianniuzu.com:444/exportExcel",
  detailywy: "https://api.xianniuzu.com:444/detailywy",
};
